export const FileUploadIcon = () => (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.776 14.618a4.384 4.384 0 0 0 2.26-.17 4.41 4.41 0 0 0 1.877-1.276 4.468 4.468 0 0 0-.385-6.229 4.384 4.384 0 0 0-4.284-.92 4.975 4.975 0 0 0-1.693-2.878 4.916 4.916 0 0 0-6.26-.036 4.975 4.975 0 0 0-1.728 2.86 4.312 4.312 0 0 0-1.151-.159c-.715 0-1.42.175-2.053.51a4.42 4.42 0 0 0-1.58 1.412 4.458 4.458 0 0 0-.489 4.103 4.44 4.44 0 0 0 1.206 1.748 4.383 4.383 0 0 0 3.99.974M11.504 8.984V14.5"
      stroke="#EB541E"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="m9.057 11.31 2.476-2.493 2.418 2.434"
      stroke="#EB541E"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
