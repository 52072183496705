export const FileInputDeleteIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="#000" fillOpacity=".3" />
      <path d="m10 10 14 14M10 24l14-14" stroke="#fff" />
    </svg>
  );
};
