import { Result } from "antd";
import { rem } from "polished";
import { ChangeEvent, Reducer, ReducerState, useEffect, useReducer, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PhoneNumberCertificationResult } from "~//interfaces";
import { debounce } from "lodash";
import {
    RegisterFormRowItem,
    RegisterFormSection,
    LicenceField,
} from "~/components";
import { validateImageFile } from "~/utils/validation.utils";
import { phoneNumberAutoFormat } from "../../../utils/phoneAutoFormat";
import { api } from "~/woozooapi";

const reducer: Reducer<
    {
        doctorLicenseFileName: string;
        specialLicenseFileName: string;
        businessLicenseFileName: string;
    },
    {
        type: string;
        payload: any;
    }
> = (state, action) => {
    switch (action.type) {
        case "setDoctorLicenceFileName":
            return { ...state, doctorLicenseFileName: action.payload };
        case "setPharmacyRegistrationCertificateFile":
            return { ...state, specialLicenseFileName: action.payload };
        case "setBusinessLicenseFile":
            return { ...state, businessLicenseFileName: action.payload }
        default:
            return state;
    }
};

const initialState: ReducerState<typeof reducer> = {
    doctorLicenseFileName: "", // 약국면허증
    specialLicenseFileName: "", // 약국개설등록증
    businessLicenseFileName: "" // 사업자등록증
};
// WARNING: For GET requests, body is set to null by browsers.
var data = "";

export const DoctorInfoForm = () => {
    const [phoneValue, setPhoneValue] = useState("");
    const [registerFormState, dispatch] = useReducer(reducer, initialState);
    const { register, setValue, setError, trigger, getValues, formState } =
        useFormContext();
    const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>();
    const [userId, setUserId] = useState("")

    const handleInput = debounce((e) => {
        api.counselor.id_check(`${e.target.value}`).then((res: any) => {
            if (!res.status) {
                setError("email", {
                    type: "sameId",
                    message: "중복된 아이디 입니다."
                })
            }
        })
    }, 1500);

    useEffect(() => {
        const profilePic = getValues("profilePic");
        if (typeof profilePic === "string" && profilePic.includes("aws")) {
            setProfilePicUrl(profilePic);
        }
    }, [getValues, formState]);

    const handleDoctorLicenseUpload = async (file: File) => { // 약사 면허증 등록
        const result = validateImageFile(file);
        console.log("result", result);
        if (!result.valid) {
            setError("pharmacist_license_file", {
                message: "파일 용량 초과"
            });
            return;
        }
        dispatch({ type: "setDoctorLicenceFileName", payload: file.name });
        const response = await api.fileUpload({
            file,
            name: "doctor-license",
        });
        setValue("pharmacist_license_file", response.uploaded[0].id);
        await trigger("pharmacist_license_file");
    };

    const handleSpecialLicenseUpload = async (file: File) => { // 약국개설등록증 등록
        const result = validateImageFile(file);
        if (!result.valid) {
            setError("pharmacy_registration_certificate_file", {
                message: "파일 용량 초과"
            });
            return;
        }
        dispatch({ type: "setPharmacyRegistrationCertificateFile", payload: file.name });
        const response = await api.fileUpload({
            file,
            name: "special-license",
        });
        setValue("pharmacy_registration_certificate_file", response.uploaded[0].id);
        await trigger("pharmacy_registration_certificate_file");
    };

    const handlBusinessLicenseUpload = async (file: File) => { // 사업자등록증 등록
        const result = validateImageFile(file);
        if (!result.valid) {
            setError("business_license_file", {
                message: "파일 용량 초과"
            });
            return;
        }
        dispatch({ type: "setBusinessLicenseFile", payload: file.name });
        const response = await api.fileUpload({
            file,
            name: "special-license",
        });
        setValue("business_license_file", response.uploaded[0].id);
        await trigger("business_license_file");
    };

    const handleDoctorLicenseDelete = async () => { // 약사 면허증 삭제
        setValue("certificate_image", undefined);
        await trigger("certificate_image");
        dispatch({ type: "setDoctorLicenceFileName", payload: "" });
    };

    const handleSpecialLicenseDelete = async () => { // 약국개설등록증 삭제
        setValue("pharmacy_registration_certificate_file ", undefined);
        await trigger("pharmacy_registration_certificate_file ");
        dispatch({ type: "setPharmacyRegistrationCertificateFile", payload: "" });
    };

    const handleBusinessLicenseDelete = async () => { // 사업자등록증 삭제
        setValue("business_license_file", undefined);
        await trigger("business_license_file");
        dispatch({ type: "setBusinessLicenseFile", payload: "" });
    };

    const PhoneOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targetValue = phoneNumberAutoFormat(e.target.value);
        setPhoneValue(targetValue);
    };

    return (
        <RegisterFormSection>
            <RegisterFormRowItem
                label="이름"
                placeholder="이름을 입력해 주세요."
                autoComplete="off"
                id="username"
                type="text"
                required
                {...register("username")}
            />
            <RegisterFormRowItem
                value={phoneValue}
                label="휴대폰 번호"
                placeholder="숫자만 입력해주세요."
                maxLength={13}
                type="mobile"
                required
                {...register("mobile", {
                    onChange: (e) => {
                        PhoneOnChange(e),
                            setValue('mobile', e.target.value);
                    }
                })}
            >
            </RegisterFormRowItem>
            <RegisterFormRowItem
                id="email"
                type="email"
                label="이메일(아이디)"
                autoComplete="off"
                placeholder="이메일을 입력해 주세요."
                required
                {...register("email", {
                    onChange: (e) => {
                        if (e.target.value.length > 0) {
                            handleInput(e)
                        }
                    }
                })}
            />
            <RegisterFormRowItem
                id="password"
                type="password"
                label="비밀번호"
                autoComplete="new-password"
                placeholder="비밀번호를 입력해 주세요."
                required
                {...register("password")}
            />
            <RegisterFormRowItem
                id="password2"
                type="password"
                label="비밀번호 확인"
                autoComplete="off"
                placeholder="비밀번호를 한번 더 입력해 주세요."
                required
                {...register("password2")}
            />
            <LicenceField
                required
                name="pharmacist_license_file"
                label="약사 면허증"
                fileName={registerFormState.doctorLicenseFileName}
                handleUpload={handleDoctorLicenseUpload}
                handleDelete={handleDoctorLicenseDelete}
            />
            <LicenceField
                required
                name="pharmacy_registration_certificate_file"
                label="약국개설등록증"
                fileName={registerFormState.specialLicenseFileName}
                handleUpload={handleSpecialLicenseUpload}
                handleDelete={handleSpecialLicenseDelete}
            />
            <LicenceField
                required
                name="business_license_file"
                label="사업자등록증"
                fileName={registerFormState.businessLicenseFileName}
                handleUpload={handlBusinessLicenseUpload}
                handleDelete={handleBusinessLicenseDelete}
            />
        </RegisterFormSection>
    );
};
