import Link from "next/link";
import { useRouter } from "next/router";
import { rem } from "polished";
import { useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Aside,
  AutoLoginToggle,
  Div,
  FormFieldErrorMessage,
  Input,
} from "~/components";
import { styled } from "~/stitches.config";
import { loginFormSchema } from "~/utils/form.utils";
import woozoocareLogo from '../../public/woozoocare.png';
import { Image } from "~/components";
import { api } from "~/woozooapi"

const LoginFormSectionWrapper = styled("article", {
  position: "relative",
  flex: "auto",
  display: "flex",
  flexDirection: "column",
  color: "$gray01",
  padding: `${rem(72)} ${rem(40)} ${rem(90)} ${rem(50)}`,
  h1: {
    fontSize: rem(24),
    fontWeight: "normal",
    marginBottom: rem(56),
  },
  form: {
    fontSize: rem(15),
    display: "flex",
    flexDirection: "column",
    'input[type="email"]': {
      marginBottom: rem(15),
    },
    'input[type="submit"]': {
      marginTop: rem(30),
      marginBottom: rem(70),
      height: rem(60),
      fontSize: rem(15),
      backgroundColor: "$primary",
    },
  },
  aside: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const StyledInput = styled("div", {
  width: rem(55.4),
  height: rem(23),
  borderRadius: rem(18),
  border: "solid 2px #e73e11",
  letterSpacing: "-0.4px",
  backgroundColor: "#e8440a",
  marginTop: rem(30),
  marginLeft: rem(-28),
  textAlign: "center",
  color: "#fff",
  lineHeight: 1.4,
  Zindex: 20,
  padding: 3
});

const Title = styled("div", {
  display: 'flex',
  alignSelf: 'center',
  marginBottom: '38.8px'
});


const resolver: Resolver<{ email: string; password: string }> =
  yupResolver(loginFormSchema);

export const LoginFormSection = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({
    mode: "onChange",
    resolver,
  });
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const router = useRouter();

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    api.counselor.login({
      email,
      password
    }).then((res: any) => {
      if (res.status) {
        localStorage.setItem("userAuthData", JSON.stringify(res.result));
        router.push('/main')
      } else {
        alert('아이디와 패스워드 확인해 주세요.')
      }
    })
  };

  return (
    <LoginFormSectionWrapper>
      <Title>
        <Image src={woozoocareLogo} width={160} height={58.6} />
        <StyledInput style={{ fontSize: 10 }}>신약국</StyledInput>
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Div
          css={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input
            aria-label="이메일"
            usage="loginPage"
            type="email"
            placeholder="이메일을 입력해 주세요"
            {...register("email", { required: true })}
          />
          <Input
            aria-label="비밀번호"
            usage="loginPage"
            type="password"
            placeholder="비밀번호를 입력해 주세요"
            {...register("password", { required: true })}
          />
          <Div css={{ position: "absolute", bottom: rem(-24), left: rem(2) }}>
            {errors.email?.message && (
              <FormFieldErrorMessage>
                {errors.email.message}
              </FormFieldErrorMessage>
            )}
            {apiErrorMessage && (
              <FormFieldErrorMessage>{apiErrorMessage}</FormFieldErrorMessage>
            )}
          </Div>
        </Div>

        <Input usage="loginPage" type="submit" value="로그인" />
        <Aside css={{ a: { color: "$gray05", textDecoration: "underline" } }}>
          <AutoLoginToggle />
          {/* <Link href="/auth/recovery">아이디 비밀번호 찾기</Link> */}
          <Div
            css={{
              a: {
                fontSize: rem(14),
                color: "$primary",
                textDecoration: "underline",
              },
            }}
          >
            <Link href="/auth/register">회원가입</Link>
          </Div>
        </Aside>
      </form>
    </LoginFormSectionWrapper>
  );
};
