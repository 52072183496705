import { AxiosResponse } from "axios";
import { axios } from "./baseAxiosInstance";
import { URLS } from "./urls";
import { FileUploadResponse } from "~/interfaces";

export const fileUpload = async ({
  file,
  name,
  prefix,
  suffix,
}: {
  file: File;
  name?: string;
  prefix?: string;
  suffix?: string;
}) => {
  const fileUploadData = new FormData();

  fileUploadData.append("business_license_file", file);
  fileUploadData.append("thumbnail", "false");
  fileUploadData.append("root", "drugstore/user-media/");

  // if (name) {
  //   fileUploadData.append("name", name);
  // }

  // if (prefix) {
  //   fileUploadData.append("prefix", prefix);
  // }

  // if (suffix) {
  //   fileUploadData.append("suffix", suffix);
  // }

  const res = await axios.request<
    FileUploadResponse,
    AxiosResponse<FileUploadResponse>,
    FormData
  >({
    url: `${
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
        ? "https://server.woozoo.clinic/api/base/media/s3/upload"
        : "https://dev.server.woozoo.clinic/api/base/media/s3/upload"
    }`,
    method: "POST",
    data: fileUploadData,
  });

  return res.data;
};
