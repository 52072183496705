import { rem } from "polished";
import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: {
    bp1: "(min-width:1024px)",
  },
  theme: {
    colors: {
      black: "#000000",
      white: "#ffffff",
      red: "#ff6161",
      darkRed: "#d8430e",
      orange: "#e29118",
      yellow: "#ffdc25",
      yellowwishgreen: "#83b126",
      green: "#60ae92",
      blue: "#569aff",
      violet: "#955fee",
      pink: "#f03fff",
      primary: "#eb541e",
      primaryFont: "#ff6c3e",
      orange01: "#d8430e",
      gray01: "#333333",
      gray02: "#404040",
      gray03: "#666666",
      gray04: "#999999",
      gray05: "#b4b4b4",
      gray06: "#eeeeee",
      gray07: "#f1f1f1",
      gray08: "#f7f7f7",
      gray09: "#4c4c4f",
      black01: "#272729",
      black10: "rgba(0, 0, 0, 0.1)",
      black20: "rgba(0, 0, 0, 0.2)",
      black30: "rgba(0, 0, 0, 0.3)",
      black40: "rgba(0, 0, 0, 0.4)",
      black50: "rgba(0, 0, 0, 0.5)",
      black60: "rgba(0, 0, 0, 0.6)",
      black70: "rgba(0, 0, 0, 0.7)",
      black80: "rgba(0, 0, 0, 0.8)",
      black90: "rgba(0, 0, 0, 0.9)",
      white10: "#c4c4c4",
      white20: "rgba(255, 255, 255, 0.2)",
      white30: "rgba(255, 255, 255, 0.3)",
      white40: "rgba(255, 255, 255, 0.4)",
      white50: "rgba(255, 255, 255, 0.5)",
      white60: "rgba(255, 255, 255, 0.6)",
      white70: "rgba(255, 255, 255, 0.7)",
      white80: "rgba(255, 255, 255, 0.8)",
      white90: "rgba(255, 255, 255, 0.9)",
      waitingStatusBg: "#fff6e9",
      finishedStatusBg: "#f7faef",
      failedStatusBg: "#f5f5f5",
      payReadyStatusBg: "#f6f7f9",
      terminatedStatusBg: "#f7faef",
      loginPageBg: "#2B2F37",
      shadow: "0deg 0% 80%",
      fileInputBgColor: "rgba(235, 84, 30, 0.06)",
      privateReservationStatusWaitingBg: "#f2f7fb",
      privateReservationStatusFinishedBg: "#f7faef",
      privateReservationStatusCanceledBg: "#f5f5f5",
      privateReservationStatusBookedBg: "#ecf6f3",
    },
    shadows: {
      profilePic: `0.1px 0.6px 0.7px hsl(var(--colors-shadow) / 0.64),
        1.3px 11.6px 13.1px -2.5px hsl(var(--colors-shadow) / 0.64)`,
      button: "0 3px 3px 0 rgba(140, 140, 140, 0.3);",
      diagnosisState: "0px 40px 20px -40px #ccc",
    },
    fonts: {
      base: "Noto Sans KR",
    },
    fontSizes: {
      "h1-27": "1.7rem",
      "h2-26": "1.62rem",
      "h3-25": "1.55rem",
      "s1-24": "1.5rem",
      "s2-22": "1.3rem",
      "s3-20": "1.25rem",
      "s4-19": "1.18rem",
      "s5-18": "1.12rem",
      "p1-17": "1.06rem",
      "p2-16": "1rem",
      "p3-15": "0.9rem",
      "p4-14": "0.87rem",
      "p5-13": "0.81rem",
      "p6-12": "0.75rem",
      "p7-11": "0.68rem",
    },
    sizes: {
      globalMinWidth: `${rem(720)}`,
      globalMaxWidth: `${rem(1024)}`,
      globalHorizontalPadding: `${rem(110)}`,
      dialogHeight: `calc(100vh - ${rem(300)})`,
    },
  },
});
