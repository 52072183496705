import { AxiosResponse } from "axios";
import { getSession } from "next-auth/react";
import { axios } from "./baseAxiosInstance";
import { URLS } from "./urls";
import {
    DoctorInfoResponse,
    DoctorSignUpResponse,
    Counselor,
    CounselorPayload,
    KakaoOrderCancelType,
    KaKaoOrderType
} from "~/interfaces";

export const counselor = {
    signUp: async (payload: Counselor) => {
        const sessions = await getSession();
        const { data } = await axios.request<
            DoctorSignUpResponse,
            AxiosResponse<DoctorSignUpResponse>,
            Counselor
        >({
            url: URLS.COUNSELOR.SIGN_UP,
            method: "POST",
            data: payload,
            headers: {
                Authorization: `jwt ${sessions?.accessToken}`,
            },
        });

        return data;
    },

    info: async (id: number) => {
        const sessions = await getSession();

        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.INFO(id),
            method: "GET",
            headers: {
                Authorization: `jwt ${sessions?.accessToken}`,
            },
        });
        return data;
    },

    calculate: async (id: number | undefined, year: number) => {
        const sessions = await getSession();

        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.CALCULATE(id, year),
            method: "GET",
            headers: {
                Authorization: `jwt ${sessions?.accessToken}`,
            },
        });
        return data;
    },

    update: async (id: number | undefined, payload: CounselorPayload) => {
        const sessions = await getSession();
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.UPDATE(id),
            method: "PATCH",
            data: payload,
            headers: {
                Authorization: `jwt ${sessions?.accessToken}`,
            },
        });

        return data;
    },

    id_check: async (id: any) => {
        const sessions = await getSession();
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.ID_CHECK(id),
            method: "GET",
        });
        return data;
    },

    login: async (payload: any) => {
        const sessions = await getSession();
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.LOGIN,
            data: payload,
            method: "POST",
        });
        return data;
    },

    pending: async () => {
        const key: any = localStorage.getItem('userAuthData');
        const parsedObject = JSON.parse(key);
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.PENDING,
            method: "GET",
            headers: {
                key: `${parsedObject.key}`,
                token: `${parsedObject.token}`,
            },
        });
        return data;
    },

    /** 카카오 퀵 등록 */
    quick_order: async (payload: KaKaoOrderType) => {
        const key: any = localStorage.getItem('userAuthData');
        const parsedObject = JSON.parse(key);
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.KAKAO_QUICK_ORDER,
            method: "POST",
            data: payload,
            headers: {
                key: `${parsedObject.key}`,
                token: `${parsedObject.token}`,
            },
        });
        return data;
    },

    /** 카카오 퀵 조회 */
    quick_order_get: async (id: string) => {
        const key: any = localStorage.getItem('userAuthData');
        const parsedObject = JSON.parse(key);
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.KAKAO_QUICK_GET(id),
            method: "GET",
            headers: {
                key: `${parsedObject.key}`,
                token: `${parsedObject.token}`,
            },
        });
        return data;
    },

    /** 카카오 퀵 취소 */
    quick_order_cancel: async (payload: KakaoOrderCancelType) => {
        const key: any = localStorage.getItem('userAuthData');
        const parsedObject = JSON.parse(key);
        const { data } = await axios.request<
            DoctorInfoResponse,
            AxiosResponse<DoctorInfoResponse>
        >({
            url: URLS.COUNSELOR.KAKAO_QUICK_CANCEL,
            method: "POST",
            data: payload,
            headers: {
                key: `${parsedObject.key}`,
                token: `${parsedObject.token}`,
            },
        });
        return data;
    },


}
